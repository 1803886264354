import {FC, useState} from 'react';
import {useChangeTask} from "../../hooks/useTask";
import {ICloseComponent} from "../../interfaces/Components/ICloseComponent";

export const CloseComponent: FC<ICloseComponent> = (props: ICloseComponent) => {
    const changeTaskHook = useChangeTask(),
        [showCloseTask, setShowCloseTask] = useState<Boolean>(false),
        closeTask = () => {
            changeTaskHook.data({
                id: props.taskId,
                description: 'Zamknięcie zlecenia',
                status: 6
            });
            setShowCloseTask(!showCloseTask);
        };


    return props.status != 6 && (
        <section className="rounded-card">
            <div className="rounded-card__widebtn">
                <div>
                    {showCloseTask && (
                        <div className="white-opacity">
                            <button type="button" className="white-opacity__close" onClick={() => setShowCloseTask(!showCloseTask)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g transform="translate(-16 -40)" opacity="0.38">
                                        <g transform="translate(16 40)">
                                            <rect width="24" height="24" fill="none"></rect>
                                            <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <div className="white-opacity__wrapper">
                                <div className="task-end-confirm">
                                    <div className="task-end-confirm__title">Zakończ zadanie</div>
                                    <div className="task-end-confirm__date">{props.taskNumber}</div>
                                    <div className="task-end-confirm__action">
                                        <button className="blue-btn blue-btn--green" type="button" onClick={() => closeTask()}>
                                                <span className="blue-btn__icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19.059" viewBox="0 0 25 19.059">
                                                        <path d="M11.355,20.627,5.428,14.7l-2.018,2,7.945,7.945L28.41,7.594l-2-2Z" transform="translate(-3.41 -5.59)" fill="#fff"/>
                                                    </svg>
                                                </span>
                                            Zakończ
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <button className="task-end-btn" onClick={() => setShowCloseTask(!showCloseTask)}>
                            <span className="task-end-btn__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19.059" viewBox="0 0 25 19.059">
                                    <path d="M11.355,20.627,5.428,14.7l-2.018,2,7.945,7.945L28.41,7.594l-2-2Z" transform="translate(-3.41 -5.59)" fill="#fff"/>
                                </svg>
                            </span>
                    Zakończ i zamknij
                </button>
            </div>
        </section>
    );
};
