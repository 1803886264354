import {FC, useEffect, useState} from 'react';
import {useAdmins} from "../../hooks/useAdmins";
import {IAdmin} from "../../interfaces/IAdmin";
import {IAdminModalComponent} from "../../interfaces/Components/IAdminModalComponent";

export const AdminModalComponent: FC<IAdminModalComponent> = (props: IAdminModalComponent) => {
    const adminsAjax = useAdmins({
            responsibile: props.isResponsible,
            _order: 'asc',
            _orderBy: 'name'
        }),
        [admins, setAdmins] = useState<Array<IAdmin>>([]),
        adminSearch = (val: string) => {
            if (!adminsAjax.data || !adminsAjax.data.list) {
                return;
            }
            const newAdmins = adminsAjax.data.list.filter((admin: IAdmin) => {
                return (admin.name.toLowerCase().includes(val));
            });
            setAdmins(newAdmins);
        },
        saveAndClose = () => {
            props.changeAdminModal(admins.filter((admin) => admin.checked));
            props.changeModalVisible(false);
        },
        setAdminCheck = (adminId: number) => {
            if (!admins) {
                return;
            }
            const newAdmins = admins.map((admin: IAdmin, adminIndex: number) => {
                if (admin.id == adminId) {
                    if (admin.checked == undefined) {
                        admin.checked = true;
                    } else {
                        admin.checked = !admin.checked;
                    }
                }
                return admin;
            })
            setAdmins(newAdmins);
        };
    useEffect(() => {
        if (adminsAjax.data && adminsAjax.data.list) {
            setAdmins(adminsAjax.data.list);
        }
    }, [adminsAjax.data]);

    return props.isModalVisible && (
        <div className="task-info-screen task-info-screen--top zIndexTop">
            <header className="top">
                <div className="container">
                    <div className="actions">
                        <div className="actions__left">
                            <button type="button" className="top__menu-btn"><span></span><span></span><span></span></button>
                            <h1 className="top__title">Wybierz administratorów</h1></div>
                        <div className="actions__right">
                            <button className="top-close" type="button" onClick={() => props.changeModalVisible(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g transform="translate(-16 -40)" opacity="0.38">
                                        <g transform="translate(16 40)">
                                            <rect width="24" height="24" fill="none"></rect>
                                            <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container container--main">
                <div className="task-admins">
                    <div className="task-admins__search">
                        <div className="field">
                            <input type="text" placeholder="Szukaj..." className="field__input" maxLength={50} onChange={(event) => adminSearch(event.target.value)}/>
                        </div>
                    </div>
                    <div className="task-admins__items">
                        {admins && admins.map((admin: IAdmin) => (
                            <div className="user-item" onClick={() => setAdminCheck(admin.id)} key={admin.id}>
                                <div className="user-item__icon">
                                    <label className={`round-check${admin.checked ? ' round-check--checked' : ''}`}>
                                        <span className="round-check__button">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19.059" viewBox="0 0 25 19.059">
                                                <path d="M11.355,20.627,5.428,14.7l-2.018,2,7.945,7.945L28.41,7.594l-2-2Z" transform="translate(-3.41 -5.59)" fill="#fff"/>
                                            </svg>
                                        </span>
                                    </label>
                                </div>
                                <div className="user-item__name">
                                    {admin.name}
                                </div>
                            </div>
                        ))}
                    </div>
                    {admins.filter((admin) => admin.checked).length && (
                        <button type="button" className="plus-button" onClick={() => saveAndClose()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"></path>
                            </svg>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
