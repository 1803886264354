import {FC} from 'react';
import {ITaskTaskCommentComponent} from "../../interfaces/Components/ITaskTaskCommentComponent";

export const TaskTaskCommentComponent: FC<ITaskTaskCommentComponent> = (props: ITaskTaskCommentComponent) => {

    return (
        <section className="rounded-card">
            <div className="task-description">
                <div className="task-info-headline">
                    Uwagi
                </div>
                <div className="task-description__p">
                    <p className="task-description__p">
                        {props.description}
                    </p>
                </div>
            </div>
        </section>
    );
};
