import {FC} from 'react';
import {ITaskDescriptionComponent} from "../../interfaces/Components/ITaskDescriptionComponent";

export const TaskDescriptionComponent: FC<ITaskDescriptionComponent> = (props: ITaskDescriptionComponent) => {

    return (
        <section className="rounded-card">
            <div className="task-service-description">
                <div className="task-info-headline">
                    Opis do serwisu
                </div>
                <div className="task-service-description__items">
                    {props.Procedures.length > 0 && (
                        <div className="task-service-description__item">
                            <div className="task-service-description__item-desc">
                                <p className="task-description__p"/>
                            </div>
                        </div>
                    )}
                    {props.Procedures.map((procedure: any) => (
                        <div className="task-service-description__item" key={procedure.procedureid}>
                            <div className="task-service-description__item-title">
                                {procedure.name}
                            </div>
                            <div className="task-service-description__item-desc">
                                {procedure.result_desc}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
