import {ITaskStatuses} from "../interfaces/ITaskStatuses";

export const taskStatuses: Array<ITaskStatuses> = [
    {
        id: -3,
        name: 'aktywne',
        filter: {
            status: {
                in: [1, 2, 3, 4]
            }
        }
    }, {
        id: -2,
        name: 'nieaktywne',
        filter: {
            status: {
                in: [5, 6]
            }
        }
    }, {
        id: -1,
        name: 'wszystkie bez zamkniętych',
        filter: {
            status: {
                ne: [6]
            }
        }
    }, {
        id: 1,
        name: 'nowa',
        filter: {
            status: 1
        }
    }, {
        id: 2,
        name: 'nierozpoczęta',
        filter: {
            status: 2
        }
    }, {
        id: 3,
        name: 'rozpoczęta',
        filter: {
            status: 3
        }
    }, {
        id: 4,
        name: 'niezakończona',
        filter: {
            status: 4
        }
    }, {
        id: 5,
        name: 'zakończona',
        filter: {
            status: 5
        }
    }, {
        id: 6,
        name: 'zamknięta',
        filter: {
            status: 6
        }
    }, {
        id: 7,
        name: 'zamknięta i rozliczona',
        filter: {
            status: 7
        }
    }, {
        id: -4,
        name: 'zamknięte telefonicznie',
        filter: {
            fixtype: 2,
            status: 6
        }
    }
]