import {FC, useEffect, useState} from 'react';
import {useChangeVat, useUnsetVat, useVat} from "../../hooks/useVat";
import {IPaidComponent} from "../../interfaces/Components/IPaidComponent";

export const PaidComponent: FC<IPaidComponent> = (props: IPaidComponent) => {
    const activeVatList = useVat({active: true}),
        changeVatHook = useChangeVat(),
        unsetVatHook = useUnsetVat(),
        [netto, setNetto] = useState<number>(props.netto),
        [showPaidTask, setShowPaidTask] = useState<Boolean>(props.showPaidTask),
        [showVatSaveButton, setShowVatSaveButton] = useState<Boolean>(false),
        [vat, setVat] = useState<number>(props.vat),
        changeNettoVat = (val: any) => {
            setNetto(val);
            setShowVatSaveButton(true);
        },
        changeVat = (val: any) => {
            setVat(val);
            setShowVatSaveButton(true);
        },
        togglePaidTask = () => {
            if (showPaidTask) {
                unsetVatHook.data(props.taskId);
            }
            setShowPaidTask(!showPaidTask);
        },
        saveVat = () => {
            changeVatHook.data({
                id: props.taskId,
                netto: netto,
                vat: vat
            });
        };
    useEffect(() => {
        setShowPaidTask(props.showPaidTask);
    }, [props.showPaidTask]);
    useEffect(() => {
        setNetto(props.netto);
    }, [props.netto]);
    useEffect(() => {
        setVat(props.vat);
    }, [props.vat]);

    return (
        <section className="rounded-card">
            <div className="task-payable-order">
                <div className="actions">
                    <div className="actions__left">
                        <div className="task-info-headline">
                            Zlecenie odpłatne
                        </div>
                    </div>
                    {props.status != 6 && (
                        <div className="actions__right" onClick={() => togglePaidTask()}>
                            <div className={`switch${showPaidTask ? ' switch--on' : ''}`}>
                                <div className="switch__path"/>
                                <button type="button" className="switch__btn"/>
                            </div>
                        </div>
                    )}
                </div>
                {showPaidTask && activeVatList.data && activeVatList.data.list && (
                    <div className="task-payable-order__fields task-payable-order__fields--transition-enter-done">
                        <div className="field">
                            <input type="number" step="0.01" min="0" pattern="^\d*(\.\d{0,2})?$" placeholder="Wpisz kwotę netto" className="field__input" value={netto}
                                   onChange={(event) => changeNettoVat(event.target.value)}/>
                        </div>
                        <div className="dropdown">
                            <select className="dropdown__select" defaultValue={vat} onChange={(event) => changeVat(event.target.value)}>
                                {activeVatList.data.list.map((vatAvaiable: any) => (
                                    <option value={vatAvaiable.id} key={vatAvaiable.id}>{vatAvaiable.vatname}</option>
                                ))}
                            </select>
                        </div>
                        {showVatSaveButton && (
                            <button className="blue-btn" type="button" onClick={() => saveVat()}>
                                Zapisz
                            </button>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};
