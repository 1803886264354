import {ChangeEvent, FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useAuth} from "../../hooks/useAuth";
import {IUserCredentials} from "../../interfaces/IUserCredentials";
import {LocalStorageService} from "../../services/LocalStorageService";

const initialState = {
    login: "",
    pass: "",
};

export const Login: FC = () => {
    const [inputs, setInputs] = useState<IUserCredentials>(initialState),
        {isLoading, loginUser, isError, errors} = useAuth(),
        {getLastError, getShowError, setDefaultLastError} = LocalStorageService(),
        [formError, setFormError] = useState(getShowError()),
        [formErrorText, setFormErrorText] = useState(getLastError()),
        loginClass = `login-field${inputs.login.length ? ' login-field--filled' : ''} ${formError ? ' login-field--error' : ''}`,
        handleInputChange = (key: string, value: string) => {
            setInputs((oldState) => {
                const newState = {...oldState, [key]: value};
                return newState;
            });
        },
        {handleSubmit} = useForm(),
        onSubmit = async () => {
            if (!inputs['login'] || !inputs['pass']) {
                setFormError(true);
            } else {
                setFormError(false);
                setDefaultLastError();
                setFormErrorText('Login, bądź hasło niepoprawne.');
                loginUser(inputs);
            }
        };

    useEffect(() => {
        if (isError) {
            setFormError(true);
        }
    }, [isError]);

    useEffect(() => {
        if (getShowError()) {
            setFormErrorText(getLastError());
            setFormError(true);
            setDefaultLastError();
        }
    }, [getLastError]);

    return (
        <form className="login-screen" onSubmit={handleSubmit(onSubmit)}>
            <div className="container">
                <div className="login-screen__logo">
                    <img src={process.env.PUBLIC_URL + `/static/media/logo.svg`} alt="MMS"/>
                </div>
                <div className="flex-column">
                    <div className="login-screen__field">
                        <label className={`${loginClass}`}>
                            <span className="login-field__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path
                                        d="M12,5.9A2.1,2.1,0,1,1,9.9,8,2.1,2.1,0,0,1,12,5.9m0,9c2.97,0,6.1,1.46,6.1,2.1v1.1H5.9V17c0-.64,3.13-2.1,6.1-2.1M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                                        transform="translate(-4 -4)" fill="#fff" opacity="0.5"/>
                                </svg>
                            </span>
                            <span className="login-field__label">
                                Login
                            </span>
                            <input type="text" placeholder="Login" className="login-field__input" onChange={(event: ChangeEvent<HTMLInputElement>) => { handleInputChange("login", event.target.value) }}/>
                            {formError ? (
                                <span className="login-field__error">{formErrorText}</span>
                            ) : ''}
                        </label>
                    </div>
                    <div className="login-screen__field">
                        <label className={`login-field ${inputs.pass.length ? 'login-field--filled' : ''}`}>
                            <span className="login-field__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="9.818" viewBox="0 0 18 9.818">
                                    <path d="M10.532,9.273a4.909,4.909,0,1,0,0,3.273h3.559v3.273h3.273V12.545H19V9.273ZM5.909,12.545a1.636,1.636,0,1,1,1.636-1.636A1.641,1.641,0,0,1,5.909,12.545Z"
                                          transform="translate(-1 -6)" fill="#fff" opacity="0.5"/>
                                </svg>
                            </span>
                            <span className="login-field__label">
                                Hasło
                            </span>
                            <input type="password" placeholder="Hasło" className="login-field__input" onChange={(event: ChangeEvent<HTMLInputElement>) => { handleInputChange("pass", event.target.value); }}/>
                        </label>
                    </div>
                    <div className="actions">
                        <div className="actions__left">
                            <button type="button" className="login-link">
                                Zapomniałeś hasła?
                            </button>
                        </div>
                        <div className="actions__right">
                            <button type="submit" className="login-btn">
                                Zaloguj
                                <span className="login-btn__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                        <path d="M10,4,8.943,5.058,13.128,9.25H4v1.5h9.128L8.943,14.943,10,16l6-6Z" transform="translate(-4 -4)" fill="#fff"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
