import {FC, useEffect, useState} from 'react';
import {useTask} from "../../hooks/useTask";
import {GlobalDamageComponent} from "./globalDamageComponent";
import {PaidComponent} from './paidComponent';
import {StatusComponent} from './statusComponent';
import {CloseComponent} from "./closeComponent";
import {TaskCommentsComponent} from "./taskCommentsComponent";
import {TaskDescriptionComponent} from "./taskDescriptionComponent";
import {TaskTaskCommentComponent} from "./taskTaskCommentComponent";
import {TaskInfoComponent} from "./taskInfoComponent";
import {ITaskStatuses} from "../../interfaces/ITaskStatuses";
import {ITaskRecord} from "../../interfaces/Components/ITaskRecord";

export const Task: FC<ITaskRecord> = (props: ITaskRecord) => {
    const task = useTask(props.recordId),
        [selectedStatus, setSelectedStatus] = useState<number>(1),
        [netto, setNetto] = useState<number>(0),
        [showGlobalTask, setShowGlobalTask] = useState<Boolean>(false),
        [showPaidTask, setShowPaidTask] = useState<Boolean>(false),
        [vatId, setVatId] = useState<number>(6);

    useEffect(() => {
        if (task.data) {
            setShowGlobalTask(task.data.globaldamageid > 0);
            setShowPaidTask(task.data.payable != '0');
            setNetto(task.data.netto);
            setVatId(task.data.vatid);
            setSelectedStatus(task.data.status);
        }
    }, [task.data]);

    return task.data && (
        <>
            <TaskInfoComponent task={task.data}/>
            <TaskTaskCommentComponent description={task.data.description}/>
            <TaskDescriptionComponent Procedures={task.data.Procedures}/>
            <TaskCommentsComponent taskId={props.recordId} description={task.data.description} status={task.data.status}/>
            <CloseComponent taskId={props.recordId} status={task.data.status} taskNumber={task.data.taskNumber}/>
            <StatusComponent taskId={props.recordId} allTaskStatuses={task.data.allTaskStatuses.filter((status: ITaskStatuses) => status.id >= selectedStatus && status.id < 7)}
                             globaldamageid={task.data.globaldamageid} selectedStatus={selectedStatus}/>
            <PaidComponent taskId={props.recordId} showPaidTask={showPaidTask} netto={netto} status={task.data.status} vat={vatId}/>
            <GlobalDamageComponent taskId={props.recordId} showGlobalTask={showGlobalTask} globaldamageid={task.data.globaldamageid} status={task.data.status}/>
        </>
    );
};
