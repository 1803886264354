import {FC, useEffect, useState} from 'react';
import {useChangeGlobalDamage, useGlobalDamages, useUnsetGlobalDamage} from "../../hooks/useGlobalDamages";
import {IGlobalDamageComponent} from "../../interfaces/Components/IGlobalDamageComponent";

export const GlobalDamageComponent: FC<IGlobalDamageComponent> = (props: IGlobalDamageComponent) => {
    const globalDamages = useGlobalDamages({
            _order: 'desc', _orderBy: 'id', filter: {
                'status': {
                    type: 'in',
                    data: [1, 2, 3, 4]
                }
            }
        }),
        changeGlobalDamageHook = useChangeGlobalDamage(),
        unsetGlobalDamageHook = useUnsetGlobalDamage(),
        [showGlobalTask, setShowGlobalTask] = useState<Boolean>(props.showGlobalTask),
        changeGlobalDamage = (globalDamageId: any) => {
            changeGlobalDamageHook.data({
                id: props.taskId,
                globaldamageid: globalDamageId
            })
        },
        toggleGlobalDamage = () => {
            if (showGlobalTask) {
                unsetGlobalDamageHook.data(props.taskId);
            }
            setShowGlobalTask(!showGlobalTask);
        };
    useEffect(() => {
        setShowGlobalTask(props.showGlobalTask);
    }, [props.showGlobalTask]);

    return (
        <section className="rounded-card">
            <div className="task-payable-order">
                <div className="actions">
                    <div className="actions__left">
                        <div className="task-info-headline">
                            Awaria globalna
                        </div>
                    </div>
                    <div className="actions__right" onClick={() => toggleGlobalDamage()}>
                        <div className={`switch${showGlobalTask ? ' switch--on' : ''}`}>
                            <div className="switch__path"/>
                            <button type="button" className="switch__btn"/>
                        </div>
                    </div>
                </div>
                {showGlobalTask && globalDamages.data && globalDamages.data.list && (
                    <div className="task-payable-order__fields">
                        <div className="dropdown">
                            <select className="dropdown__select" defaultValue={props.globaldamageid} onChange={(event) => changeGlobalDamage(event.target.value)}>
                                <option value="0">wybierz</option>
                                {globalDamages.data && globalDamages.data.list && globalDamages.data.list.map((globalDamage: any) => (
                                    <option value={globalDamage.id} key={globalDamage.id}>{globalDamage.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};
