import {createContext, Dispatch, FC, useEffect, useReducer} from "react";
import {useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";
import {axiosInstance} from "../../../api/http";
import {LocalStorageService} from "../../../services/LocalStorageService";

const initialState: IState = {
    status: "STATE_NOT_CHECKED",
};

type Action =
    | { type: "NOT_LOGGED" }
    | { type: "UNAUTHORIZED" }
    | { type: "LOGGED_IN_WITH_EXISTING_TOKEN"; payload?: { token: string } }
    | { type: "LOGGED_IN"; payload?: { token: string } };

const loginReducer = (state: IState, action: Action) => {
    const {setToken, clearToken} = LocalStorageService();

    switch (action.type) {
        case "LOGGED_IN":
            if (action.payload) {
                setToken(action.payload.token);
            }
            return {...state, status: "LOGGED_IN"};

        case "LOGGED_IN_WITH_EXISTING_TOKEN":
            if (action.payload?.token) {
                axiosInstance.defaults.headers.common["auth"] = `Bearer ${action.payload.token}`;
            }

            return {...state, status: "LOGGED_IN_WITH_EXISTING_TOKEN"};

        case "UNAUTHORIZED":
        case "NOT_LOGGED":
            axiosInstance.defaults.headers.common["auth"] = "";
            clearToken();
            return {...state, status: action.type};
    }
};

export const LoginStateContext = createContext<{
    state: IState;
    dispatch: Dispatch<Action>;
}>({state: initialState, dispatch: () => null});

interface Props {
    children: React.ReactNode;
}

export const LoginState: FC<Props> = ({children}) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(loginReducer, initialState);
    const queryClient = useQueryClient();
    const {getToken} = LocalStorageService();

    useEffect(() => {
        const token = getToken();

        if (token) {
            dispatch({type: "LOGGED_IN_WITH_EXISTING_TOKEN", payload: {token}});
        } else {
            dispatch({type: "NOT_LOGGED"});
        }

        axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (error.response) {
                    if (
                        error.response.status === 401 ||
                        error.response.status === 403 ||
                        error.response === undefined
                    ) {
                        dispatch({type: "UNAUTHORIZED"});
                    }
                }
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const {status} = state;

        switch (status) {
            case "LOGGED_IN":
                // navigate(process.env.PUBLIC_URL + "/", { replace: true });
                break;

            case "LOGGED_IN_WITH_EXISTING_TOKEN":
                break;

            case "UNAUTHORIZED":
            case "NOT_LOGGED":
                queryClient.invalidateQueries();
                // navigate(process.env.PUBLIC_URL + "/login", { replace: true });
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <LoginStateContext.Provider value={{state, dispatch}}>
            {children}
        </LoginStateContext.Provider>
    );
};
