export const endpoints = {
    admins: {
        list: "/api/admins/",
        record: "/api/admins/{id}"
    },
    auth: "/api/auth",
    globalDamages: {
        change: '/api/damages/{id}/setglobal',
        list: '/api/globaldamages',
    },
    damagesHistory: {
        add: '/api/damageshistory',
        change: '/api/damageshistory/{id}',
        list: '/api/damageshistory'
    },
    firm: "/api/firms/active",
    logout: "/api/logout",
    sms: '/api/sms_history',
    task: {
        list: '/api/damages',
        record: '/api/damages/{id}'
    },
    user: '/api/users/{id}',
    vat: {
        list: '/api/vatrates'
    }
};
