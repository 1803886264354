import {FC, useState} from 'react';
import {useChangeStatus} from "../../hooks/useTask";
import {AdminModalComponent} from "./adminModalComponent";
import {ChangeStatusModal} from "./changeStatusModal";
import {IAdmin} from "../../interfaces/IAdmin";
import {IStatus} from "../../interfaces/IStatus";
import {IStatusComponent} from "../../interfaces/Components/IStatusComponent";

export const StatusComponent: FC<IStatusComponent> = (props: IStatusComponent) => {
    const
        [isChangeAdminModalVisible, setIsChangeAdminModalVisible] = useState<Boolean>(false),
        [isChangeAdminResponsibleModalVisible, setIsChangeAdminResponsibleModalVisible] = useState<Boolean>(false),
        [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState<Boolean>(false),
        [selectedStatus, setSelectedStatus] = useState<number>(props.selectedStatus),
        [admins, setAdmins] = useState<Array<IAdmin>>([]),
        [adminsResponsible, setAdminsResponsible] = useState<Array<IAdmin>>([]),
        changeStatusHook = useChangeStatus(),
        changeSelectedStatus = (val: string) => {
            setIsChangeStatusModalVisible(!isChangeStatusModalVisible);
            setSelectedStatus(parseInt(val));
        },
        changeStatus = (data: IStatus) => {
            changeStatusHook.data({
                admins: admins,
                adminsResponsible: adminsResponsible,
                id: props.taskId,
                description: data.description,
                status: selectedStatus
            });
        };

    return (
        <>
            <AdminModalComponent isModalVisible={isChangeAdminModalVisible} taskId={props.taskId} changeModalVisible={setIsChangeAdminModalVisible} changeAdminModal={setAdmins} isResponsible={false}/>
            <AdminModalComponent isModalVisible={isChangeAdminResponsibleModalVisible} taskId={props.taskId} changeModalVisible={setIsChangeAdminResponsibleModalVisible}
                                 changeAdminModal={setAdminsResponsible} isResponsible={true}/>
            <ChangeStatusModal selectedStatus={selectedStatus} isChangeStatusModalVisible={isChangeStatusModalVisible} changeStatus={changeStatus} countAdmins={admins.length}
                               countAdminsResponsible={adminsResponsible.length} setIsChangeStatusModalVisible={setIsChangeStatusModalVisible}
                               setIsChangeAdminModalVisible={setIsChangeAdminModalVisible} setIsChangeAdminResponsibleModalVisible={setIsChangeAdminResponsibleModalVisible}/>
            <section className="rounded-card">
                <div className="task-status">
                    <div className="task-info-headline">
                        Status zlecenia
                    </div>
                    {props.globaldamageid > 0 && (
                        <p>
                            Nie można zmienić statusu, póki zlecenie podpiete jest pod awarie globalną
                        </p>
                    )}
                    {props.globaldamageid == 0 && (
                        <div className="dropdown">
                            <select className="dropdown__select" value={props.selectedStatus} onChange={(event) => changeSelectedStatus(event.target.value)}
                            disabled={props.selectedStatus >= 6}>
                                {props.allTaskStatuses.map((statusAvailable: any) => (
                                    <option value={statusAvailable.id} key={statusAvailable.id}>{statusAvailable.name}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};
