import {FC} from 'react';

export const Footer: FC = () => {

    return (
        <div className="task-screen__tasks-bottom">
            <div className="loading">
                <div className="lds-ring lds-ring--black">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};
