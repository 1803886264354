export const restructureDate = (dateNumber: number, returnDate: Boolean = true) => {
    if (dateNumber == 0) {
        return 'Brak danych';
    }
    const date = new Date(dateNumber * 1000),
        year = date.getFullYear();
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        dateString = '',
        hour = '' + (date.getHours()),
        minute = '' + (date.getMinutes()),
        timeString = '';

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    if (hour.length < 2) {
        hour = '0' + hour;
    }
    if (minute.length < 2) {
        minute = '0' + minute;
    }
    dateString = day + '-' + month + '-' + year;
    timeString = hour + ':' + minute;
    return dateString + ' ' + timeString;
};