import {FC, useState} from 'react';
import {ITaskInfoComponent} from "../../interfaces/Components/ITaskInfoComponent";
import {TaskCallComponent} from './taskCallComponent';
import {TaskSmsComponent} from './taskSmsComponent';

export const TaskInfoComponent: FC<ITaskInfoComponent> = (props: ITaskInfoComponent) => {
    const [isCallModalVisible, setIsCallModalVisible] = useState<Boolean>(false),
        [isSmsModalVisible, setIsSmsModalVisible] = useState<Boolean>(false),
        [selectedPhone, setSelectedPhone] = useState<number>(0),
        openMap = () => {
            if (props.task && props.task.affectedadreses) {
                window.open(`https://www.google.pl/maps/search/${encodeURI(props.task.affectedadreses.join(''))}`, '_blank', 'noreferrer');
            }
        },
        openModalCall = (phone: number) => {
            setSelectedPhone(phone);
            setIsCallModalVisible(true);
        },
        openModalSms = (phone: number) => {
            setSelectedPhone(phone);
            setIsSmsModalVisible(true);
        };
    return (
        <>
            <TaskSmsComponent isModalVisible={isSmsModalVisible} phone={selectedPhone} changeModalVisible={setIsSmsModalVisible} userId={props.task.userData.id}/>
            <TaskCallComponent isModalVisible={isCallModalVisible} phone={selectedPhone} changeModalVisible={setIsCallModalVisible}/>
            <section className="rounded-card">
                <div className="task-info">
                    <div className="task-info__left">
                        <div className={`task-icon task-icon--status${props.task.status}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29.819" viewBox="0 0 30 29.819">
                                <path
                                    d="M30.563,25.473,18.172,13.082a8.739,8.739,0,0,0-2.042-9.4A9,9,0,0,0,6.054,1.917l5.855,5.855L7.824,11.857,1.833,6A8.775,8.775,0,0,0,3.6,16.078,8.739,8.739,0,0,0,13,18.12L25.389,30.511a1.316,1.316,0,0,0,1.906,0l3.132-3.132a1.226,1.226,0,0,0,.136-1.906Z"
                                    transform="translate(-0.956 -1.1)" fill="#fff"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="task-info__right">
                        <div className="task-info__title">
                            Zlecenie serwisowe:
                        </div>
                        <div className="task-info__date">
                            {props.task.taskNumber}
                        </div>
                        <div className="task-info__address">
                            {props.task.affectedadreses.map((affectedAddress: string, addressIndex: number) => (
                                <div key={addressIndex}>
                                    {affectedAddress}
                                </div>
                            ))}
                        </div>
                        <div className="task-info__company">
                            {props.task.userData.boklogin}<br/>{props.task.user_fullname}
                        </div>
                        {props.task.fixstartdate > 0 && (
                            <div className="task-info__created">
                                <div className="task-info__created-title">Dodano:</div>
                                <div className="task-info__created-date">{props.task.fixstartdateString.split(' ')[0]}</div>
                                <div className="task-info__created-time">{props.task.fixstartdateString.split(' ')[1]}</div>
                            </div>
                        )}
                        {props.task.fixfinishdate > 0 && (
                            <div className="task-info__completed">
                                <div className="task-info__completed-title">Termin wykonania:</div>
                                <div className="task-info__completed-date">{props.task.fixfinishdateString.split(' ')[0]}</div>
                                <div className="task-info__completed-time">{props.task.fixfinishdateString.split(' ')[1]}</div>
                            </div>
                        )}
                        <div className="task-info__actions">
                            {props.task.affectedadreses.length > 0 && (
                                <button className="blue-btn" type="button" onClick={() => openMap()}>
                                            <span className="blue-btn__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <path d="M17,3,3,8.857v.762L8.32,11.68,10.373,17h.762Z" transform="translate(-3 -3)" fill="#fff"/>
                                                </svg>
                                            </span>
                                    DOJAZD
                                </button>
                            )}
                            {(props.task.userData.phone1) && (
                                <>
                                    <button className="blue-btn" type="button" onClick={() => openModalCall(props.task.userData.phone1)}>
                                        <span className="blue-btn__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                <path
                                                    d="M5.816,9.059a11.782,11.782,0,0,0,5.126,5.126l1.711-1.711a.773.773,0,0,1,.793-.187,8.872,8.872,0,0,0,2.777.443.78.78,0,0,1,.778.778v2.714a.78.78,0,0,1-.778.778A13.221,13.221,0,0,1,3,3.778.78.78,0,0,1,3.778,3H6.5a.78.78,0,0,1,.778.778,8.836,8.836,0,0,0,.443,2.777.781.781,0,0,1-.194.793Z"
                                                    transform="translate(-3 -3)" fill="#fff"/>
                                            </svg>
                                        </span>
                                        {props.task.userData.phone1}
                                    </button>
                                    <button className="blue-btn" type="button" onClick={() => openModalSms(props.task.userData.phone1)}>
                                        <span className="blue-btn__icon blue-btn__icon--sms">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 448" version="1.1">
                                                <g transform="matrix(1,0,0,1,0,-32)">
                                                    <path
                                                        d="M256,32C114.6,32 0,125.1 0,240C0,289.6 21.4,335 57,370.7C44.5,421.1 2.7,466 2.2,466.5C0,468.8 -0.6,472.2 0.7,475.2C2,478.2 4.8,480 8,480C74.3,480 124,448.2 148.6,428.6C181.3,440.9 217.6,448 256,448C397.4,448 512,354.9 512,240C512,125.1 397.4,32 256,32ZM128.2,304L116,304C111.6,304 108,300.4 108,296L108,280C108,275.6 111.6,272 116,272L128.3,272C134.3,272 138.7,268.5 138.7,265.4C138.7,264.1 137.9,262.7 136.6,261.6L114.7,242.8C106.2,235.6 101.4,225.3 101.4,214.7C101.4,193.4 120.4,176.1 143.8,176.1L156,176.1C160.4,176.1 164,179.7 164,184.1L164,200.1C164,204.5 160.4,208.1 156,208.1L143.7,208.1C137.7,208.1 133.3,211.6 133.3,214.7C133.3,216 134.1,217.4 135.4,218.5L157.3,237.3C165.8,244.5 170.6,254.8 170.6,265.4C170.7,286.7 151.6,304 128.2,304ZM320,296C320,300.4 316.4,304 312,304L296,304C291.6,304 288,300.4 288,296L288,227.8L263.2,283.6C260.3,289.5 251.8,289.5 248.9,283.6L224,227.8L224,296C224,300.4 220.4,304 216,304L200,304C195.6,304 192,300.4 192,296L192,192C192,183.2 199.2,176 208,176L224,176C230.1,176 235.6,179.4 238.3,184.8L256,220.2L273.7,184.8C276.4,179.4 282,176 288,176L304,176C312.8,176 320,183.2 320,192L320,296ZM368.3,304L356,304C351.6,304 348,300.4 348,296L348,280C348,275.6 351.6,272 356,272L368.3,272C374.3,272 378.7,268.5 378.7,265.4C378.7,264.1 377.9,262.7 376.6,261.6L354.7,242.8C346.2,235.6 341.4,225.3 341.4,214.7C341.4,193.4 360.4,176.1 383.8,176.1L396,176.1C400.4,176.1 404,179.7 404,184.1L404,200.1C404,204.5 400.4,208.1 396,208.1L383.7,208.1C377.7,208.1 373.3,211.6 373.3,214.7C373.3,216 374.1,217.4 375.4,218.5L397.3,237.3C405.8,244.5 410.6,254.8 410.6,265.4C410.7,286.7 391.7,304 368.3,304Z"
                                                        fill="#fff"/>
                                                </g>
                                            </svg>
                                        </span>
                                        {props.task.userData.phone1}
                                    </button>
                                </>
                            )}
                            {(props.task.userData.phone2) && (
                                <>
                                    <button className="blue-btn" type="button" onClick={() => openModalCall(props.task.userData.phone2)}>
                                        <span className="blue-btn__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                <path
                                                    d="M5.816,9.059a11.782,11.782,0,0,0,5.126,5.126l1.711-1.711a.773.773,0,0,1,.793-.187,8.872,8.872,0,0,0,2.777.443.78.78,0,0,1,.778.778v2.714a.78.78,0,0,1-.778.778A13.221,13.221,0,0,1,3,3.778.78.78,0,0,1,3.778,3H6.5a.78.78,0,0,1,.778.778,8.836,8.836,0,0,0,.443,2.777.781.781,0,0,1-.194.793Z"
                                                    transform="translate(-3 -3)" fill="#fff"/>
                                            </svg>
                                        </span>
                                        {props.task.userData.phone2}
                                    </button>
                                    <button className="blue-btn" type="button" onClick={() => openModalSms(props.task.userData.phone2)}>
                                        <span className="blue-btn__icon blue-btn__icon--sms">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 448" version="1.1">
                                                <g transform="matrix(1,0,0,1,0,-32)">
                                                    <path
                                                        d="M256,32C114.6,32 0,125.1 0,240C0,289.6 21.4,335 57,370.7C44.5,421.1 2.7,466 2.2,466.5C0,468.8 -0.6,472.2 0.7,475.2C2,478.2 4.8,480 8,480C74.3,480 124,448.2 148.6,428.6C181.3,440.9 217.6,448 256,448C397.4,448 512,354.9 512,240C512,125.1 397.4,32 256,32ZM128.2,304L116,304C111.6,304 108,300.4 108,296L108,280C108,275.6 111.6,272 116,272L128.3,272C134.3,272 138.7,268.5 138.7,265.4C138.7,264.1 137.9,262.7 136.6,261.6L114.7,242.8C106.2,235.6 101.4,225.3 101.4,214.7C101.4,193.4 120.4,176.1 143.8,176.1L156,176.1C160.4,176.1 164,179.7 164,184.1L164,200.1C164,204.5 160.4,208.1 156,208.1L143.7,208.1C137.7,208.1 133.3,211.6 133.3,214.7C133.3,216 134.1,217.4 135.4,218.5L157.3,237.3C165.8,244.5 170.6,254.8 170.6,265.4C170.7,286.7 151.6,304 128.2,304ZM320,296C320,300.4 316.4,304 312,304L296,304C291.6,304 288,300.4 288,296L288,227.8L263.2,283.6C260.3,289.5 251.8,289.5 248.9,283.6L224,227.8L224,296C224,300.4 220.4,304 216,304L200,304C195.6,304 192,300.4 192,296L192,192C192,183.2 199.2,176 208,176L224,176C230.1,176 235.6,179.4 238.3,184.8L256,220.2L273.7,184.8C276.4,179.4 282,176 288,176L304,176C312.8,176 320,183.2 320,192L320,296ZM368.3,304L356,304C351.6,304 348,300.4 348,296L348,280C348,275.6 351.6,272 356,272L368.3,272C374.3,272 378.7,268.5 378.7,265.4C378.7,264.1 377.9,262.7 376.6,261.6L354.7,242.8C346.2,235.6 341.4,225.3 341.4,214.7C341.4,193.4 360.4,176.1 383.8,176.1L396,176.1C400.4,176.1 404,179.7 404,184.1L404,200.1C404,204.5 400.4,208.1 396,208.1L383.7,208.1C377.7,208.1 373.3,211.6 373.3,214.7C373.3,216 374.1,217.4 375.4,218.5L397.3,237.3C405.8,244.5 410.6,254.8 410.6,265.4C410.7,286.7 391.7,304 368.3,304Z"
                                                        fill="#fff"/>
                                                </g>
                                            </svg>
                                        </span>
                                        {props.task.userData.phone2}
                                    </button>
                                </>
                            )}
                            {(props.task.userData.phone3) && (
                                <>
                                    <button className="blue-btn" type="button" onClick={() => openModalCall(props.task.userData.phone3)}>
                                        <span className="blue-btn__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                <path
                                                    d="M5.816,9.059a11.782,11.782,0,0,0,5.126,5.126l1.711-1.711a.773.773,0,0,1,.793-.187,8.872,8.872,0,0,0,2.777.443.78.78,0,0,1,.778.778v2.714a.78.78,0,0,1-.778.778A13.221,13.221,0,0,1,3,3.778.78.78,0,0,1,3.778,3H6.5a.78.78,0,0,1,.778.778,8.836,8.836,0,0,0,.443,2.777.781.781,0,0,1-.194.793Z"
                                                    transform="translate(-3 -3)" fill="#fff"/>
                                            </svg>
                                        </span>
                                        {props.task.userData.phone3}
                                    </button>
                                    <button className="blue-btn" type="button" onClick={() => openModalSms(props.task.userData.phone3)}>
                                        <span className="blue-btn__icon blue-btn__icon--sms">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 448" version="1.1">
                                                <g transform="matrix(1,0,0,1,0,-32)">
                                                    <path
                                                        d="M256,32C114.6,32 0,125.1 0,240C0,289.6 21.4,335 57,370.7C44.5,421.1 2.7,466 2.2,466.5C0,468.8 -0.6,472.2 0.7,475.2C2,478.2 4.8,480 8,480C74.3,480 124,448.2 148.6,428.6C181.3,440.9 217.6,448 256,448C397.4,448 512,354.9 512,240C512,125.1 397.4,32 256,32ZM128.2,304L116,304C111.6,304 108,300.4 108,296L108,280C108,275.6 111.6,272 116,272L128.3,272C134.3,272 138.7,268.5 138.7,265.4C138.7,264.1 137.9,262.7 136.6,261.6L114.7,242.8C106.2,235.6 101.4,225.3 101.4,214.7C101.4,193.4 120.4,176.1 143.8,176.1L156,176.1C160.4,176.1 164,179.7 164,184.1L164,200.1C164,204.5 160.4,208.1 156,208.1L143.7,208.1C137.7,208.1 133.3,211.6 133.3,214.7C133.3,216 134.1,217.4 135.4,218.5L157.3,237.3C165.8,244.5 170.6,254.8 170.6,265.4C170.7,286.7 151.6,304 128.2,304ZM320,296C320,300.4 316.4,304 312,304L296,304C291.6,304 288,300.4 288,296L288,227.8L263.2,283.6C260.3,289.5 251.8,289.5 248.9,283.6L224,227.8L224,296C224,300.4 220.4,304 216,304L200,304C195.6,304 192,300.4 192,296L192,192C192,183.2 199.2,176 208,176L224,176C230.1,176 235.6,179.4 238.3,184.8L256,220.2L273.7,184.8C276.4,179.4 282,176 288,176L304,176C312.8,176 320,183.2 320,192L320,296ZM368.3,304L356,304C351.6,304 348,300.4 348,296L348,280C348,275.6 351.6,272 356,272L368.3,272C374.3,272 378.7,268.5 378.7,265.4C378.7,264.1 377.9,262.7 376.6,261.6L354.7,242.8C346.2,235.6 341.4,225.3 341.4,214.7C341.4,193.4 360.4,176.1 383.8,176.1L396,176.1C400.4,176.1 404,179.7 404,184.1L404,200.1C404,204.5 400.4,208.1 396,208.1L383.7,208.1C377.7,208.1 373.3,211.6 373.3,214.7C373.3,216 374.1,217.4 375.4,218.5L397.3,237.3C405.8,244.5 410.6,254.8 410.6,265.4C410.7,286.7 391.7,304 368.3,304Z"
                                                        fill="#fff"/>
                                                </g>
                                            </svg>
                                        </span>
                                        {props.task.userData.phone3}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
