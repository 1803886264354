import React, {FC, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';

import {LoginState} from './shared/components/LoginState';
import {MainRouter} from './routing';

const queryClient = new QueryClient(),
    Service: FC = () => (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <LoginState>
                    <Suspense>
                    <MainRouter/>
                    </Suspense>
                </LoginState>
            </BrowserRouter>
        </QueryClientProvider>
    );

ReactDOM.render(
    <React.StrictMode>
        <Service/>
    </React.StrictMode>,
    document.getElementById('root')
);
