import {useMutation,useQueryClient} from "react-query";
import {endpoints} from "../api/endpoints";
import {postJSON} from "../api/http";
import {useState} from "react";

const smsSend = async (data: any) =>
    postJSON(endpoints.sms, {
        userid: data.userId,
        message: data.message
    });

export const useSmsSend = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            smsSend,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    alert(error?.data.message);
                    setErrors(Object.values(error?.data.message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("task");
                },
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};
