import {useMutation, useQuery, useQueryClient} from "react-query";
import {endpoints} from "../api/endpoints";
import {deleteJSON, extendedPostJSON, getFilter, getJSON} from "../api/http";
import {IPagination} from "../core/types";
import {useContext, useState} from "react";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

const getGlobalDamages = async (stringParams: string) =>
    getJSON(
        [endpoints.globalDamages.list, stringParams && "?", stringParams].join("")
    );

interface Props extends IPagination {
}

export const useGlobalDamages = (props: Props) => {
    const limit = props && props._limit ? `_limit=${props._limit}` : '',
        order = props && props._order ? `_orderby=${props._order}` : '',
        orderBy = props && props._orderBy ? `_orderby=${props._orderBy}` : '',
        page = props && props._page ? `_page=${props._page}` : '',
        filter = props && props.filter ? getFilter(props.filter) : '',
        {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error} = useQuery(
            `globalDamages_`,
            () => getGlobalDamages([page, limit, order, orderBy, filter].filter(Boolean).join('&')),
            {
                onError: async () => {
                    setLastError('Brak uprawnień');
                    await deleteJSON(endpoints.auth);
                    dispatch({type: 'NOT_LOGGED'});
                },
                retry: false,
                staleTime: Infinity,
                suspense: true
            }
        );

    return {
        isLoading,
        data: data?.data,
        error,
    };
};

const changeGlobalDamage = async (data: any) =>
    extendedPostJSON(endpoints.globalDamages.change.replaceAll('{id}', data.id.toString()), {
        globaldamageid: data.globaldamageid
    });

export const useChangeGlobalDamage = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            changeGlobalDamage,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    alert(error?.data.message);
                    setErrors(Object.values(error?.data.message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("globalDamage");
                },
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};

const unsetGlobalDamage = async (recordId: Number) =>
    deleteJSON(endpoints.globalDamages.change.replaceAll('{id}', recordId.toString()));

export const useUnsetGlobalDamage = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            unsetGlobalDamage,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    alert(error?.data.message);
                    setErrors(Object.values(error?.data.message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("task");
                },
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};
