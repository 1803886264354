import {useColorMode} from "@chakra-ui/react";
import {FC, useContext, useState, useEffect} from "react";
import {Routes, Route, Outlet, useSearchParams} from "react-router-dom";
import {axiosInstance, getJSON} from "../api/http";
import {
    Login,
    Tasks
} from "../pages";
import {LoginStateContext} from "../shared/components/LoginState";
import {useFirm} from "../hooks/useFirm";
import {endpoints} from "../api/endpoints";
import {TaskFooter} from "../pages/Tasks/footer";

export const ContentRouter: FC = () => {
    const {state} = useContext(LoginStateContext);

    if (state.status === "LOGGED_IN" || "LOGGED_IN_WITH_EXISTING_TOKEN") {
        if (axiosInstance.defaults.headers.common["auth"])
            return (
                <Outlet/>
            );
    }

    return null;
};

export const MainRouter: FC = () => {
    const {id, name} = useFirm().data,
        {dispatch} = useContext(LoginStateContext),
        [isLoading, setIsLoading] = useState<Boolean>(false),
        loginUser = async () => {
            setIsLoading(true);
            await getJSON(endpoints.auth).then((data) => {
                setIsLoading(false);
                if (data.status === 200) {
                    dispatch({type: "LOGGED_IN", payload: {token: data.data.session_id}});
                }
            }).catch(() => {
                setIsLoading(false);
                dispatch({type: "NOT_LOGGED"});
            });
        };

    useEffect(() => {
        if (name) {
            document.title = `Serwis - ${name}`;
        }
        if (id) {
            const head = document.head,
                link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = 'i/firm' + id + '/theme.css';
            head.appendChild(link);
            return () => {
                head.removeChild(link);
            }
        }
    }, [name, id]);

    useEffect(() => {
        loginUser();
    }, []);

    const {state} = useContext(LoginStateContext);
    if (isLoading) {
        return (
            <div className='login-screen'>
                <TaskFooter isLoading={isLoading}/>
            </div>
        )
    } else {
        return (
            <Routes>
                {state.status === ("LOGGED_IN" || "LOGGED_IN_WITH_EXISTING_TOKEN") ? (
                    <Route path={process.env.PUBLIC_URL + "/"} element={<ContentRouter/>}>
                        <Route index element={<Tasks/>}/>
                    </Route>
                ) : (
                    <Route path={process.env.PUBLIC_URL + "/"} element={<Login/>}/>
                )}
                <Route path={process.env.PUBLIC_URL + "*"} element={<div>404</div>}/>
            </Routes>
        );
    }
};
