import {FC} from 'react';
import {ITaskFooterComponent} from "../../interfaces/Components/ITaskFooterComponent";

export const TaskFooter: FC<ITaskFooterComponent> = (props: ITaskFooterComponent) => {
    return (
        <div className={`task-screen__tasks-bottom${props.isLoading ? ' task-screen__tasks-bottom--loading' : ''}`}>
            <div className="loading">
                <div className="lds-ring lds-ring--black">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        </div>
    );
};
